/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"
import Golden from "@components/sharedComponents/customersLogos/logos/Golden.svg"
import SanJorge from "@components/sharedComponents/customersLogos/logos/Sanjorge.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import Aquaexport from "@components/sharedComponents/customersLogos/logos/Aquaexport.svg"
import Renta4 from "@components/sharedComponents/customersLogos/logos/Renta4.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/script-chat-live.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/chat-en-vivo-web.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/como-vender-por-chat.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/soporte-tecnico-via-chat.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/script-chatbot.jpg"

// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageChannels/pageWebchat/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageChannels/pageWebchat/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageChannels/pageWebchat/images/accordionLeft/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageWebchat.jpg"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageChannels/pageWebchat/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageChannels/pageWebchat/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageChannels/pageWebchat/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageChannels/pageWebchat/conversions/images/imageFour.webp"

import { TitleUnderlineWebchatOne, TitleUnderlineWebchatTwo } from "@components/pageChannels/components/intro/titleUnderlineWebchat"
import imageStartWebchat from "@components/pageChannels/components/intro/images/imageStartWebchat.webp"

const titleStart = (
  <p>
    Genera más conversiones y reduce el rebote de tu web con un <TitleUnderlineWebchatOne underline="Live Chat" /> <TitleUnderlineWebchatTwo underline="con IA" /> </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Acompaña a tus clientes durante todo el proceso de compra de manera inteligente, utilizando herramientas clave como agentes con IA para brindar respuestas instantáneas y personalizadas, evitando el abandono en tu web.
        <br /><br />
        Mejora tus estrategias de cross y up selling mediante workflows automatizados que se adapten al perfil del cliente potencial.
      </p>
    ),
    image: imageStartWebchat,
    textbutton: "Empezar ahora",
    link: "/hablemos/",
  },
  customersDesktop: [
    Royal,
    Golden,
    SanJorge,
    CasaRossello,
    Aquaexport,
    Renta4
  ],
  customersTablet: {
    arrayOne: [Royal, Golden, SanJorge, CasaRossello, Aquaexport, Renta4,]
  },
  articles: [
    {
      image: article1,
      title: "¿Cómo crear un script de live chat para tu página web?",
      textbody:
        "Descubre cuáles son los pasos para crear un script de live chat y cómo implementarlo en la página web de tu negocio gracias a Beex.",
      link: "https://beexcc.com/blog/script-chat-live/",
      card: true,
    },
    {
      image: article2,
      title: "Chat en vivo para web: Qué es y cómo funciona",
      textbody:
        "Te contamos todos los detalles del chat en vivo para web, cómo implementarlo y cuál es su importancia en las ventas de un e-commerce.",
      link: "https://beexcc.com/blog/chat-en-vivo-web/",
      card: true,
    },
    {
      image: article3,
      title: "¿Cómo vender por chat? 9 formas para más conversiones",
      textbody:
        "Conoce cómo vender por chat con 9 sencillas estrategias que te ayudarán a aumentar las ventas de tu negocio y a mejorar tu atención.",
      link: "https://beexcc.com/blog/como-vender-por-chat/",
    },
    {
      image: article4,
      title: "Soporte técnico vía chat: 12 tips para una mejor atención",
      textbody:
        "Si buscas tener un soporte técnico vía chat en este artículo aprenderás 12 tips claves para una buena atención.",
      link: "https://beexcc.com/blog/soporte-tecnico-via-chat/",
    },
    {
      image: article5,
      title: "6 pasos para crear un script para tu chatbot",
      textbody:
        "¿Tienes un chatbot o piensas implementar uno? Aquí te contamos cuál es la forma correcta que deben comunicarse los bots con tus clientes.",
      link: "https://beexcc.com/blog/script-chatbot/",
    },
  ],
  contacts: {
    title: "Eleva la experiencia del cliente y convierte más",
    textbody: "Tener tu primer chat en vivo será muy fácil gracias a que nuestros asesores se ajustan a las necesidades de tu empresa",
    href: "/hablemos/",
    btn: "Hablar como un experto",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Nuestros leads han aumentado desde que sumamos livechat en nuestra web",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing de Casa Rosselló",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
  ],
  accordionLeft: [
    {
      title: "Agentes IA",
      description: "<p>Implementa agentes con IA que puedan interpretar las necesidades del cliente y recomendar productos específicos, imitando la experiencia de una interacción humana. De esta forma no solo estarán para responder preguntas frecuentes, también para realizar ventas consultivas.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Reducción de carritos abandonados",
      description: "<p>Identifica señales tempranas de intención de abandono de carrito. Antes de que el usuario abandone, ofrece asistencia mediante un chat en vivo o brinda descuentos instantáneos, personalizados según el contenido del carrito y el historial de navegación del usuario.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Automatizaciones con workflows",
      description: "<p>Crea workflows que se activen según acciones específicas de los usuarios, como la visita a ciertas páginas. Inicia secuencias automatizadas de mensajes personalizados, asignación de un agente o la activación de recordatorios de productos vistos, pero no comprados.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  bannerVideo: {
    title: "¿Cómo atender a un cliente por chat? 5 claves para convertirlos en tu amig@",
    description: "<p>Aprender a cómo atender a un cliente por chat tiene sus dificultades. En primer lugar, porque estás interactuando con una persona a distancia, lo que limita la capacidad de interpretar señales no verbales y emocionales que en comunicación cara a cara serían evidentes. <br /> <br />    Nuestra frontend developer, Gabriela Ignacio, nos cuenta todos los pasos para atender a los clientes por chat que permita una mayor satisfacción y fidelidad.</p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Mejora tu lead generation",
      img: imageConversionsOne,
      text:
        "Mejora la calificación de leads en tiempo real dentro del webchat, utilizando IA para evaluar la intención de compra y el ajuste del producto basado en las interacciones del usuario. Utiliza esta información para personalizar la conversación y dirigir los leads de alta calidad al equipo adecuado.",
    },
    {
      title: "Analiza el sentimiento del cliente",
      img: imageConversionsTwo,
      text:
        "Utiliza dashboards y reportes en tiempo real para evaluar en tiempo real las emociones y tonos de las interacciones de los clientes, permitiendo a tu equipo ajustar las estrategias de comunicación y servicio al cliente sobre la marcha.",
    },
    {
      title: "Onboarding de usuarios",
      img: imageConversionsThree,
      text:
        "Desarrolla programas de onboarding personalizados que se activan a través del webchat, guiando a los usuarios a través de las características clave de tu producto o servicio y proporcionando recursos adaptados a sus necesidades específicas.",
    },
    {
      title: "Envía encuestas luego de una atención",
      img: imageConversionsFour,
      text:
        "Permite a tus usuarios calificar la atención, ya sea de un chatbot o de un agente en vivo, luego de una atención mediante el envío de encuestas de satisfacción después de una interacción.",
    },
  ],
}

export default data
